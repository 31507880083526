import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'

import styles from '../templateStyles/page.module.css'
import SEO from '../components/SEO/SEO'

export const PageTemplate = ({ title, content }) => {
  return (
    <section className={styles.container}>
    <div className={styles.page}>
        <h1 className={styles.header}>ABOUT LENGJAI</h1>
        <div className={styles.content}>
          <div
            className={styles.textBody}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = ({ data }) => {
  const { wordpressPage: page } = data
  return (
    <Layout>
      <SEO
        // FIX THE SEO!!!!
        title={data.site.siteMetadata.title}
        description={data.site.siteMetadata.description}
        pathname='https://www.lengjai.com/about'
        article
      />
      <PageTemplate title={page.title} content={page.content} />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        title
        titleTemplate
        description
        url
        image
      }
    }
  }
`
